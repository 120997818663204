import axios from "axios";

import {
  createDecipheriv,
  createCipheriv,
  randomBytes,
} from "crypto-browserify";

const TokenString = "";

export const callApi = async (url, method, token, body) => {
  const localToken = validateToken(localStorage.getItem("session"));
  try {
    if (process.env.REACT_APP_AAA === "true") {
      body = { data: encryptedaes256cbc(body, localToken) };
    }
  } catch (error) {
    // console.log("EREA", error);
  }

  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? "Bearer " + token : "",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(body),
  });
  // console.log("EREA", responsecall);
  const json = responsecall.data;
  // console.log("EREA", json);
  if (process.env.REACT_APP_AAA === "true") {
    return await descryptedaes256cbc(json.data, localToken);
  }

  return json;
};

export const callSendFornData = async (url, token, formData) => {
  const localToken = validateToken(localStorage.getItem('session'));

  const config = {
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'multipart/form-data', // Esencial para enviar FormData
    },
  };

  try {
    const response = await axios.post(url, formData, config);

    const json = response.data;
    if (process.env.REACT_APP_AAA === 'true') {
      return await descryptedaes256cbc(json.data, localToken);
    }

    return json;

    return response.data;
  } catch (error) {
    console.error('Error al subir la foto:', error);
    throw error;
  }
};


export const callApiInit = async (url, method, token, body) => {
  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? "Bearer " + token : "",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(body),
  });

  const json = responsecall.data;

  return json;
};

export const callApiLogin = async (url, method, token, body) => {
  const localToken = validateToken(localStorage.getItem("session"));
  if (process.env.REACT_APP_AAA === "true") {
    body = {
      username: encryptedaes256cbcTextPlain(body.username, localToken),
      password: encryptedaes256cbcTextPlain(body.password, localToken),
    };
    // console.log("ENCRIPTADO", body);
  }
  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? "Bearer " + token : "",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(body),
  });

  const json = responsecall.data;

  if (process.env.REACT_APP_AAA === "true") {
    return await descryptedaes256cbc(json.data, localToken);
  }

  return json;
};

export function descryptedaes256cbc(encryptedText, privateKey) {
  const encryptedArray = encryptedText.split(":");
  const iv = Buffer.from(encryptedArray[0], "hex");
  const encryptedData = Buffer.from(encryptedArray[1], "hex");
  const decipher = createDecipheriv("aes-256-ctr", Buffer.from(privateKey), iv);
  let decrypted = decipher.update(encryptedData);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  const decryptedText = decrypted.toString();
  return JSON.parse(decryptedText);
}

export function getTokenView(token) {
  return validateToken(token);
}

export function encryptedaes256cbc(data, privateKey) {
  try {
    const iv = randomBytes(16);
    // console.log(iv.toString("hex"), data, privateKey);
    const cipher = createCipheriv("aes-256-ctr", Buffer.from(privateKey), iv);
    let encrypted = cipher.update(JSON.stringify(data));
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    const encryptedText = `${iv.toString("hex")}:${encrypted.toString("hex")}`;
    // console.log(encryptedText);
    return encryptedText;
  } catch (error) {
    // console.log("EEROR", error);
  }
}

export function encryptedaes256cbcTextPlain(data, privateKey) {
  try {
    const iv = randomBytes(16);
    // console.log(iv.toString("hex"), data, privateKey);
    const cipher = createCipheriv("aes-256-ctr", Buffer.from(privateKey), iv);
    let encrypted = cipher.update(data);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    const encryptedText = `${iv.toString("hex")}:${encrypted.toString("hex")}`;
    // console.log(encryptedText);
    return encryptedText;
  } catch (error) {
    // console.log("EEROR", error);
  }
}

function validateToken(token) {
  //DES

  if (token) {
    let b = 0;
    let des = "";
    try {
      let dat = token;
      while (b < dat.length - 20) {
        des = des + dat.charAt(b);
        b = b + 21;
      }
      return des;
    } catch (error) {
      //console.log(error);
    }
  }
}
