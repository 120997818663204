import React, { lazy, useEffect } from 'react';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import {
  descryptedaes256cbc,
  encryptedaes256cbc,
  getTokenView,
} from '../apis/utils';
import { apiGetVerifyToken } from '../apis';
import { Grid, Typography } from '@mui/material';
import LoadingAnimation from '../assets/lock.json';
import Lottie from 'lottie-react';

const PrivateRouter = (props) => {
  const noView = Loadable(
    lazy(() => import('../views/autoexpertos/NoView/noview'))
  );

  useEffect(async () => {
    // console.log('TEST');
    const check = await apiGetVerifyToken();
    if (check.code === 401) {
      window.location.href = './';
    }
  }, []);

  let credencial = '';
  const localToken = getTokenView(localStorage.getItem('session'));
  let validated = false;
  let validatedView = true;
  if (process.env.REACT_APP_AAA === 'true') {
    if (!localStorage.getItem('aet')) {
      validated = false;
    } else {
      try {
        credencial = {
          data: descryptedaes256cbc(localStorage.getItem('aet'), localToken),
        };

        if (props.profile) {
          try {
            let profileUser = credencial.data.profile;
            const index = props.profile.indexOf(profileUser);

            if (index >= 0) {
              validatedView = true;
            } else {
              validatedView = false;
            }
          } catch (error) {
            // console.log(error);
          }
        } else {
          validated = true;
        }

        validated = true;
      } catch (error) {
        // console.log(error);
        validated = true;
      }

      //con token verificamos que este vigente
    }

    if (validated) {
      if (validatedView) {
        return props.component;
      } else {
        return (
          <Grid container spacing={0}>
            {/* ------------------------- row 1 ------------------------- */}

            <Grid item xs={12} lg={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // width: '90%',
                  height: '60vh',
                }}
              >
                <Lottie animationData={LoadingAnimation} />


              </div>
              <Typography style={{ textAlign: "center", fontWeight: "700", fontSize: "20px" }}>No tienes permisos para acceder a este recurso</Typography>
              <Typography style={{ textAlign: "center", fontWeight: "400", fontSize: "14px" }}>Si necesitas algun acceso comunicate con TI</Typography>
            </Grid>
          </Grid>
        );
        //MOSTRAR COMPONENTE SIN PERMISOS
      }
    } else {
      window.location.href = './';
    }
  }

  //check credencial or link to navigator login
};

export default PrivateRouter;

export const IsEditPermition = () => {
  const localToken = getTokenView(localStorage.getItem('session'));
  const credencial = {
    data: descryptedaes256cbc(localStorage.getItem('aet'), localToken),
  };

  const profileUser = credencial.data.profile;
  // console.log("profileUser", profileUser)
  if (profileUser === 1 || profileUser === 0 || profileUser === 2  || profileUser === 4) {
    return true;
  } else {
    return false;
  }
};

export const CodProfile = () => {
  const localToken = getTokenView(localStorage.getItem('session'));
  const credencial = {
    data: descryptedaes256cbc(localStorage.getItem('aet'), localToken),
  };

  const profileUser = credencial.data.profile;
  // console.log("profileUser", profileUser)

  return profileUser;
};
